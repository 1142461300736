import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import tokenImg from "../../Assets/main/aboutImg.png";

function Tokenomics() {
  return (
    <Container fluid className="tokenomics-section" id="tokenomics">
      <Container>
        <Row className="tokenomics-content">
          <Col>
            <img
              src={tokenImg}
              alt="home-img"
              className="about-logo"
              width={500}
            />
          </Col>
          <Col>
            <p className="token-text">$IsraelVsIran</p>
            <p className="token-text2">
              Our token $IsraelVsIran will be available on the Base Chain.{" "}
            </p>
            <ul className="token-text3">
              <h1>🔹Tokenomics</h1>
              <li>Supply: 1,000,000,000 </li>
              <li>LP locked</li>
              <li>Buy / Sell : 0/0</li>
              <li>Max Wallet : No Max</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Tokenomics;
