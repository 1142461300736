import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import About from "./about";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import homeImg from "../../Assets/main/banner.jpg";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className="home-content">
          <div className="home-content-top">
            <marquee className="home-text" scrollamount={18}>
              <img src={homeImg} alt="home-img" className="home-logo" />
              <span style={{ color: "red" }}>STOP</span>
              <span style={{ color: "green" }}>IT</span>
              <span style={{ color: "blue" }}>WAR, </span>
              <span style={{ color: "yellow" }}>PLEASE!!!</span>
              <img src={homeImg} alt="home-img" className="home-logo" />
            </marquee>
            <div className="home-text2">
              <span>CONTRACT: COMING SOON</span>
            </div>
          </div>
        </div>
        <About />
        <Roadmap />
        <Tokenomics />
      </Container>
    </section>
  );
}

export default Home;
