import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import roadmapImg from "../../Assets/main/roadmap.jpg";

function Roadmap() {
  return (
    <Container fluid className="roadmap-section" id="roadmap">
      <Container>
        <div id="roadmap">
          <img src={roadmapImg} alt="home-img" className="roadmapImg" />
        </div>
      </Container>
    </Container>
  );
}

export default Roadmap;
