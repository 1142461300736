import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import aboutImg from "../../Assets/main/aboutImg.png";

function About() {
  return (
    <Container fluid className="about-section" id="about">
      <div className="about-content">
        <Row className="about-top">
          <Col>
            <p className="about-text">
              Welcome to us - Prestigious project on Base chain
            </p>
            <p className="about-text about-text2">
              The world is filled with war. We are here to spread the message to
              you! "Peace". Let's spread this message everywhere by making
              IsraelVsIran trending on Base chain, "STOP IT, STOP IT!!!". And
              BUY BUY BUY IsraelVsIran on Base.
            </p>
            <p className="about-text about-text3">Peace! This is for you.</p>
          </Col>
          <Col>
            <img
              src={aboutImg}
              alt="home-img"
              className="about-logo"
              width={500}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default About;
