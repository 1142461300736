import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/main/logo.png";
import iconX from "../Assets/sup/tw.png";
import iconTl from "../Assets/sup/tl.png";
import iconUni from "../Assets/sup/uni.png";
import iconPoo from "../Assets/sup/poo.png";
import iconDex from "../Assets/sup/dex.png";
import iconCgk from "../Assets/sup/cgk.png";
import iconCmc from "../Assets/sup/cmc.png";
import iconRm from "../Assets/sup/roadmap.jpg";
import iconTk from "../Assets/sup/token.png";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar expanded={expand} fixed="top" expand="md" className="sticky">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="home-img" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}>
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" defaultActiveKey="#home">
            <Row>
              <Col>
                <Nav.Item>
                  <Nav.Link
                    href="https://t.me/israelxiranonbase"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconTl} alt="home-img" />
                    <span>TELEGRAM</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://x.com/israelxiranonbase"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconX} alt="home-img" />
                    <span>X</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://app.uniswap.org/?chain=base"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconUni} alt="home-img" />
                    <span>UNISWAP</span>
                  </Nav.Link>
                </Nav.Item>
              </Col>
              <Col>
                <Nav.Item>
                  <Nav.Link
                    href="https://www.dextools.io/app/en/base/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconDex} alt="home-img" />
                    <span>DEXTOOLS</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="#tokenomics"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconTk} alt="home-img" />
                    <span>TOKENOMICS</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="#roadmap"
                    onClick={() => updateExpanded(false)}
                    className="navbar-social">
                    <img src={iconRm} alt="home-img" />
                    <span>ROADMAP</span>
                  </Nav.Link>
                </Nav.Item>
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
